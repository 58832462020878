import GetConfigButton from '../../views/renderers/GetConfigButton';
import LinkRenderer from '../../views/renderers/LinkRenderer';
import LocalLinkRenderer from '../../views/renderers/LocalLinkRenderer';
const LOCALE_VALUE = 'en';

function millisecToHHMMSS(updated_at) {
  const dateIso = new Date().toISOString().slice(0, -1);
  const millisec = new Date(dateIso).getTime() - new Date(updated_at).getTime();

  let seconds = millisec / 1000;
  const hours = parseInt(seconds / 3600).toFixed(0);
  seconds = seconds % 3600;
  const minutes = parseInt(seconds / 60).toFixed(0);
  seconds = (seconds % 60).toFixed(0);
  return hours + ':' + minutes + ':' + seconds;
}

const columnDefsAccounts = [
  {
    field: 'id',
    headerName: 'Id',
    editable: true,
    filter: 'agNumberColumnFilter',
    enableRowGroup: true,
    checkboxSelection: true,
  },
  // {
  //   field: 'vps_id',
  //   headerName: 'VPS',
  //   filter: 'agNumberColumnFilter',
  //   editable: true,
  //   enableRowGroup: true,
  // },
  {
    field: 'vpsIp',
    headerName: 'VPS IP',
    filter: 'agTextColumnFilter',
    editable: true,
    enableRowGroup: true,
  },
  {
    field: 'imei',
    headerName: 'IMEI',
    filter: 'agTextColumnFilter',
    editable: true,
    enableRowGroup: true,
  },
  {
    field: 'mode',
    headerName: 'Mode',
    filter: 'agTextColumnFilter',
    editable: true,
    enableRowGroup: true,
  },
  {
    field: 'model',
    headerName: 'Model',
    filter: 'agTextColumnFilter',
    editable: true,
    enableRowGroup: true,
  },
  {
    field: 'signal_strength',
    headerName: 'Signal',
    editable: true,
    enableRowGroup: true,
  },
  {
    field: 'public_ip',
    headerName: 'IP',
    filter: 'agTextColumnFilter',
    editable: true,
    enableRowGroup: true,
  },
  {
    field: 'online_time',
    headerName: 'Online time',
    filter: 'agNumberColumnFilter',
    editable: true,
    enableRowGroup: true,
  },
  {
    headerName: 'Ip check',
    filter: 'agNumberColumnFilter',
    editable: true,
    enableRowGroup: true,
    valueGetter: (params) => {
      return params.data.ip_check_time
        ? millisecToHHMMSS(params.data.ip_check_time)
        : 0;
    },
  },
  {
    headerName: 'Updated ip',
    filter: 'agNumberColumnFilter',
    editable: true,
    enableRowGroup: true,
    valueGetter: (params) => {
      return params.data.updated_at
        ? millisecToHHMMSS(params.data.updated_at)
        : 0;
    },
  },
  {
    field: 'is_active',
    headerName: 'Is active',
    editable: true,
    enableRowGroup: true,
  },
  {
    field: 'interface',
    headerName: 'Interface',
    filter: 'agNumberColumnFilter',
    editable: true,
    enableRowGroup: true,
  },
  {
    headerName: 'RotationLink',
    filter: 'agNumberColumnFilter',
    editable: true,
    enableRowGroup: true,
    valueGetter: (params) => {
      if(params.data.ip_rotation_token !== '') {
        return (
          process.env.REACT_APP_CUSTOM_RESOLVERS_EXPRESS +
          'rotate?hash=' +
          params.data.ip_rotation_token
        );
      } else {
        return ''
      }
    },
  },
  {
    field: 'proxy',
    headerName: 'Proxy',
    filter: 'agTextColumnFilter',
    editable: true,
    enableRowGroup: true,
  },
  {
    headerName: 'OpenVpn',
    cellRenderer: GetConfigButton,
    editable: true,
  },
];

const columnDefsServers = [
  {
    field: 'id',
    headerName: 'Id',
    editable: true,
    filter: 'agNumberColumnFilter',
    enableRowGroup: true,
    checkboxSelection: true,
  },
  {
    field: 'ip',
    headerName: 'IP',
    editable: true,
    filter: 'agTextColumnFilter',
    enableRowGroup: true,
    checkboxSelection: true,
  },
];

export { columnDefsAccounts, columnDefsServers };
