import React, { Component } from 'react';
import { Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Auth from './views/auth/Auth';
import Main from './views/Main';
import { nhost } from '../src/lib/nhost';

export default class App extends Component {
  async componentDidMount() {
    // const isAuthenticated = await nhost.auth.isAuthenticatedAsync();
    // if (!isAuthenticated && window.location.href != '/') {
    //   console.log(window.location.href);
    // window.location.href = '/';
    // }
  }

  render() {
    return (
      <div className="App">
        <Routes>
          <Route path="/main" element={<Main />} />
          <Route path="/" element={<Auth />} />
        </Routes>
      </div>
    );
  }
}
