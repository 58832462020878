import makeApolloClient from './utils/makeApolloClient';
import gql from 'graphql-tag';

const ROTATE_PORT = gql`
  mutation RotatePort($payload: RotatePort) {
    rotatePort(payload: $payload)
  }
`;

const ENABLE_PROXY = gql`
  mutation EnableProxy($payload: ProxyCredentialsPort) {
    enableProxy(payload: $payload)
  }
`;

const ENABLE_ALL_PROXY = gql`
  mutation EnableAllProxy($payload: ProxyCredentials) {
    enableAllProxy(payload: $payload)
  }
`;

const CREATE_VPS = gql`
  mutation CreateVps($payload: UserVPSPayload) {
    createVPS(payload: $payload)
  }
`;

const CREATE_EMPTY_VPS = gql`
  mutation CreateEmptyVPS($payload: String) {
    createEmptyVPS(payload: $payload)
  }
`;

const GEN_INSTALL_CONFIG = gql`
  query GenInstallConfig($payload: UserVPSPayload) {
    GenerateInstallstionConfig(payload: $payload)
  }
`;

const GET_OPENVPN_CONFIG = gql`
  query Query($payload: Int) {
    getOpenVpnConfig(payload: $payload)
  }
`;

class ApiServiceCustomerResolvers {
  client;

  constructor(client) {
    this.client = client;
  }

  enableProxy = async (payload) => {
    try {
      const result = await this.client.mutate({
        mutation: ENABLE_PROXY,
        variables: {
          payload,
        },
      });
      console.log(result);
      return result.data;
    } catch (err) {
      console.log('ERROR:', err);
    }
  };

  enableAllProxies = async (payload) => {
    try {
      const result = await this.client.mutate({
        mutation: ENABLE_ALL_PROXY,
        variables: {
          payload,
        },
      });
      console.log(result);
      return result.data;
    } catch (err) {
      console.log('ERROR:', err);
    }
  };

  rotatePort = async (payload) => {
    try {
      const result = await this.client.mutate({
        mutation: ROTATE_PORT,
        variables: {
          payload,
        },
      });
      console.log(result);
      return result.data;
    } catch (err) {
      console.log('ERROR:', err);
    }
  };

  createVPS = async (payload) => {
    try {
      const result = await this.client.mutate({
        mutation: CREATE_VPS,
        variables: {
          payload,
        },
      });
      console.log(result);
      return result.data;
    } catch (err) {
      console.log('ERROR:', err);
    }
  };

  createEmptyVPS = async (payload) => {
    try {
      const result = await this.client.mutate({
        mutation: CREATE_EMPTY_VPS,
        variables: {
          payload,
        },
      });
      console.log(result);
      return result.data;
    } catch (err) {
      console.log('ERROR:', err);
    }
  };

  generateInstallConfig = async (payload) => {
    try {
      const result = await this.client.query({
        query: GEN_INSTALL_CONFIG,
        variables: {
          payload,
        },
      });
      console.log(result);
      return result.data;
    } catch (err) {
      console.log('ERROR:', err);
    }
  };

  getOpenVPNConfig = async (payload) => {
    try {
      const result = await this.client.query({
        query: GET_OPENVPN_CONFIG,
        variables: {
          payload,
        },
      });
      console.log(result);
      return result.data.getOpenVpnConfig;
    } catch (err) {
      console.log('ERROR:', err);
    }
  };
}

const client = makeApolloClient(
  process.env.REACT_APP_CUSTOM_RESOLVERS_URL,
  null
);
const apiServiceCustomResolvers = new ApiServiceCustomerResolvers(client);
export { apiServiceCustomResolvers };
