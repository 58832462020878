import React, { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { SUBSCRIBE_VPS, client, apiService } from '../services/ApiService';
import { columnDefsServers } from '../services/utils/columnDefs';
import Button from 'react-bootstrap/Button';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { apiServiceCustomResolvers } from '../services/ApiCustomResolvers';
import GenConfigModal from './modals/GenerateInstallConfig';
import { nhost } from '../lib/nhost';
export default class Servers extends Component {
  state = {
    selectedRow: false,
    selectedRows: [],
    installConfig: '',
    modalShow: false,
    abandonModalShow: false,
    gridRef: null,
    gridApi: null,
    rowData: [],
    columnDefs: columnDefsServers,
    defaultColDef: {
      resizable: true,
      sortable: true,
      flex: 1,
      filter: true,
    },

    autoGroupColumnDef: {
      width: 250,
    },

    containerStyle: { width: '100%', height: '900px' },
    gridStyle: { height: '100%', width: '100%' },
  };

  constructor(props) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.onSelectionChanged = this.onSelectionChanged.bind(this);
  }

  async logout() {
    localStorage.setItem('userAccessToken', '');
    await nhost.auth.signOut();
    window.location.href = '/';
  }

  openModal() {
    this.setState(() => {
      return { modalShow: true };
    });
  }

  closeModal() {
    this.setState(() => {
      return { modalShow: false };
    });
  }

  async addEmptyVps() {
    await apiServiceCustomResolvers.createEmptyVPS(
      localStorage.getItem('userId')
    );
  }

  async generateInstallConfig() {
    const selectedRows = this.state.gridRef.current.api.getSelectedRows();
    const vpsToGenerate = {
      ip: selectedRows[0].ip,
      user_id: localStorage.getItem('userId'),
    };
    const config = await apiServiceCustomResolvers.generateInstallConfig(
      vpsToGenerate
    );
    console.log(config);
    this.setState(() => {
      return { installConfig: config.GenerateInstallstionConfig };
    });
    this.openModal();
  }

  changeRowData(data) {
    this.setState(() => {
      return { rowData: data };
    });
  }

  onLoadGrid = (params) => {
    this.setState(() => {
      return { gridRef: React.createRef({ ...params }) };
    });
    this.setState(() => {
      return { gridApi: params.api };
    });
  };

  async onSelectionChanged() {
    const selectedRows = this.state.gridRef.current.api.getSelectedRows();
    if (selectedRows.length > 0) {
      this.setState(() => {
        return { selectedRow: true };
      });
    }
  }

  async componentDidMount() {
    const changeRowData = async (data) => {
      this.setState(() => {
        return { rowData: data };
      });
    };

    const observer = client.subscribe({
      query: SUBSCRIBE_VPS,
    });
    observer.subscribe({
      next(data) {
        changeRowData(data.data.vps);
      },
      error(err) {
        console.log(err);
      },
    });

    let vps = await apiService.getVps();
    this.setState(() => {
      return { rowData: vps };
    });
  }

  getRowId(params) {
    return params.data.id;
  }

  render() {
    return (
      <div>
        <Button
          className="addButton"
          onClick={() => {
            this.addEmptyVps();
          }}
          variant="primary"
        >
          Create server
        </Button>
        <Button
          disabled={!this.state.selectedRow}
          className="addButton"
          onClick={() => {
            this.generateInstallConfig();
          }}
          variant="primary"
        >
          Generate installation config
        </Button>
        <Button onClick={() => this.logout()} variant="danger" type="submit">
          Log out
        </Button>
        <GenConfigModal
          show={this.state.modalShow}
          onHide={this.closeModal}
          config={this.state.installConfig}
        />
        <div className="ag-theme-alpine" style={{ height: 800, width: '100%' }}>
          <AgGridReact
            rowData={this.state.rowData}
            getRowId={this.getRowId}
            ref={this.state.gridRef}
            columnDefs={this.state.columnDefs}
            defaultColDef={this.state.defaultColDef}
            pivotPanelShow={'always'}
            rowGroupPanelShow={'always'}
            suppressAggFuncInHeader={true}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            onGridReady={this.onLoadGrid}
            rowSelection={'multiple'}
            onSelectionChanged={this.onSelectionChanged}
            animateRows={true}
            enableRangeSelection={true}
          ></AgGridReact>
        </div>
      </div>
    );
  }
}
