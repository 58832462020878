import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { apiServiceCustomResolvers } from '../../services/ApiCustomResolvers';
import { nhost } from '../../lib/nhost';

export default class Auth extends Component {
  state = {
    id: '',
    userId: '',
    email: '',
    emailSent: false,
  };

  constructor(props) {
    super(props);
    this.login = this.login.bind(this);
    this.handleInputEmail = this.handleInputEmail.bind(this);
  }

  async handleInputEmail(event) {
    this.setState(() => {
      return { email: event.target.value };
    });
  }

  async isAuthenticated() {
    const isAuthenticated = await nhost.auth.isAuthenticatedAsync();

    if (isAuthenticated) {
      console.log('User is authenticated');
      const accessToken = nhost.auth.getAccessToken();
      localStorage.setItem('userAccessToken', accessToken);
      const user = nhost.auth.getUser();
      localStorage.setItem('userId', user.id);
      window.location.href = '/main';
      return true;
    } else {
      console.log('User is not authenticated');
      return false;
    }
  }

  async login(event) {
    event.preventDefault();
    try {
      // try to find a user with that email
      if (!(await this.isAuthenticated())) {
        console.log('email sent');
        this.setState(() => {
          return { emailSent: true };
        });
        nhost.auth.signIn({
          email: this.state.email,
        });
      }
    } catch (err) {
      console.log('err while login: ', err);
    }
  }

  async toMain() {}

  async componentDidMount() {
    this.isAuthenticated();
  }

  render() {
    return (
      <div>
        <Form className="Form" noValidate onSubmit={this.login}>
          <Form.Group className="mb-3">
            <Form.Label>Enter your email</Form.Label>
            <Form.Control
              type="text"
              onChange={this.handleInputEmail}
              value={this.state.email}
            />
          </Form.Group>
          <div className="d-grid gap-2">
            <Button className="modalButton" variant="primary" type="submit">
              Login
            </Button>
          </div>
          {this.state.emailSent ? (
            <div className="d-grid gap-2">
              <Button className="modalButton" variant="success" disabled={true}>
                Email sent
              </Button>
            </div>
          ) : (
            ''
          )}
        </Form>
      </div>
    );
  }
}
