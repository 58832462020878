import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import ProfileData from './ProfileData';
import Profile from './Profile';
import Servers from './Servers';
import { useEffect } from 'react';
import { nhost } from '../lib/nhost';

function Main(props) {
  useEffect(async () => {
    const isAuthenticated = await nhost.auth.isAuthenticatedAsync();

    if (isAuthenticated) {
      console.log('User is authenticated');
      const accessToken = nhost.auth.getAccessToken();
      localStorage.setItem('userAccessToken', accessToken);
      const user = nhost.auth.getUser();
      localStorage.setItem('userId', user.id);
      return true;
    } else {
      console.log('User is not authenticated');
      window.location.href = '/';
      return false;
    }
  });
  return (
    <Tabs
      defaultActiveKey="conversations"
      id="justify-tab-example"
      className="mb-3"
      justify
    >
      <Tab eventKey="adverts" title="Shop">
        Shop
      </Tab>
      <Tab eventKey="conversations" title="Modems">
        <Row>
          <Col sm={12}>
            <ProfileData />
          </Col>
          {/* <Col sm={1}>
            <Profile />
          </Col> */}
        </Row>
      </Tab>
      <Tab eventKey="servers" title="Servers">
        <Servers />
      </Tab>
    </Tabs>
  );
}

export default Main;
