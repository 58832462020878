import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { apiService } from '../../services/ApiService';
import { apiServiceCustomResolvers } from '../../services/ApiCustomResolvers';

export default function AddVpsModal(props) {
  const [ip = '', setIp] = useState();
  const [port = '', setPort] = useState();

  const handleInputIp = (event) => {
    setIp(event.target.value);
  };

  const handleInputPort = (event) => {
    setPort(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const vpsToCreate = {
      ip: ip + ':' + port,
      user_id: localStorage.getItem('userId'),
    };
    await apiServiceCustomResolvers.createVPS(vpsToCreate);
    props.onHide();
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add new VPS
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              type="text"
              value={ip}
              onChange={handleInputIp}
              placeholder="Enter ip"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Control
              type="number"
              value={port}
              onChange={handleInputPort}
              placeholder="Enter port"
            />
          </Form.Group>
          <div className="d-grid gap-2">
            <Button className="modalButton" variant="primary" type="submit">
              Add
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
