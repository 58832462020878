import makeApolloClient from './utils/makeApolloClient';
import gql from 'graphql-tag';

const GET_VPS = gql`
  query GetVps {
    vps {
      id
      ip
      modems {
        id
        imei
      }
    }
  }
`;

const GET_MODEMS = gql`
  query GetModems {
    modems {
      id
      imei
      carrier
      data_usage_rx
      data_usage_tx
      internet_connection
      last_ip_change
      mode
      model
      vps_id
      online_time
      public_ip
      signal_strength
      is_active
      interface
      ip_rotation_token
      ip_check_time
      updated_at
      proxies {
        ip
        port
        username
        pass
      }
      vps {
        id
        ip
      }
    }
  }
`;

const SUBSCRIBE_MODEMS = gql`
  subscription SubscribeModems {
    modems {
      id
      imei
      carrier
      data_usage_rx
      data_usage_tx
      internet_connection
      last_ip_change
      mode
      model
      vps_id
      online_time
      public_ip
      signal_strength
      is_active
      interface
      ip_rotation_token
      ip_check_time
      updated_at
      proxies {
        ip
        port
        username
        pass
      }
      vps {
        id
        ip
      }
    }
  }
`;

const SUBSCRIBE_VPS = gql`
  subscription SubscribeVps {
    vps {
      id
      ip
      modems {
        id
        imei
      }
    }
  }
`;

const CREATE_VPS = gql`
  mutation CreateVps($ip: String, $user_id: uuid) {
    insert_vps_one(object: { ip: $ip, user_id: $user_id }) {
      id
      ip
    }
  }
`;

const CREATE_EMPTY_VPS = gql`
  mutation CreateVps($user_id: uuid) {
    insert_vps_one(object: { user_id: $user_id }) {
      id
    }
  }
`;

class ApiService {
  client;

  constructor(client) {
    this.client = client;
  }

  getVps = async () => {
    try {
      const result = await this.client.query({
        query: GET_VPS,
      });
      return result.data.vps;
    } catch (err) {
      console.log('ERROR getVps:', err);
      return [];
    }
  };

  getModems = async () => {
    try {
      const result = await this.client.query({
        query: GET_MODEMS,
      });
      return result.data.modems;
    } catch (err) {
      console.log('ERROR getModems:', err);
      return [];
    }
  };

  createVps = async (ip) => {
    try {
      await this.client.mutate({
        mutation: CREATE_VPS,
        variables: {
          ip,
          user_id: localStorage.getItem('userId'),
        },
      });
    } catch (err) {
      console.log('ERROR createVps:', err);
    }
  };

  createEmptyVps = async () => {
    try {
      await this.client.mutate({
        mutation: CREATE_EMPTY_VPS,
        variables: {
          user_id: localStorage.getItem('userId'),
        },
      });
    } catch (err) {
      console.log('ERROR createEmptyVps:', err);
    }
  };
}

const client = makeApolloClient(
  process.env.REACT_APP_API_URL,
  process.env.REACT_APP_API_WS_URL
);
const apiService = new ApiService(client);
export { client, apiService, SUBSCRIBE_MODEMS, SUBSCRIBE_VPS };
