import React from 'react';
import { apiService } from '../../services/ApiService';
import { apiServiceCustomResolvers } from '../../services/ApiCustomResolvers';

export default (props) => {
  function downloadOvpn(filename, text) {
    var element = document.createElement('a');
    element.setAttribute(
      'href',
      'data:text/plain;charset=utf-8,' + encodeURIComponent(text)
    );
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  async function getOpenVPNConfig(data) {
    const config = await apiServiceCustomResolvers.getOpenVPNConfig(data.id);
    downloadOvpn('openvpn.ovpn', config);
  }

  return (
    <button onClick={async () => getOpenVPNConfig(props.data)}>
      Get openVPN config
    </button>
  );
};
