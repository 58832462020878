import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { apiService } from '../../services/ApiService';
import { apiServiceCustomResolvers } from '../../services/ApiCustomResolvers';

export default function GenConfigModal(props) {
  const config = props.config;
  const [copied, setCopied] = useState(false);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {`Your installation script` + (copied ? ' (copied)' : '')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Control
              disabled={true}
              as="textarea"
              rows={4}
              value={config}
            />
          </Form.Group>
          <div className="d-grid gap-2">
            <Button
              className="modalButton"
              variant="primary"
              onClick={() => {
                navigator.clipboard.writeText(config);
                setCopied(true);
              }}
            >
              Copy
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
