import React, { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { SUBSCRIBE_MODEMS, client, apiService } from '../services/ApiService';
import { columnDefsAccounts } from '../services/utils/columnDefs';
import Button from 'react-bootstrap/Button';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { apiServiceCustomResolvers } from '../services/ApiCustomResolvers';
import AddVpsModal from './modals/AddVpsModal';
import { nhost } from '../lib/nhost';

export default class ProfileData extends Component {
  state = {
    selectedRow: false,
    selectedRows: [],
    modalShow: false,
    abandonModalShow: false,
    gridRef: null,
    gridApi: null,
    rowData: [],
    columnDefs: columnDefsAccounts,
    defaultColDef: {
      resizable: true,
      sortable: true,
      flex: 1,
      filter: true,
    },

    autoGroupColumnDef: {
      width: 250,
    },

    containerStyle: { width: '100%', height: '900px' },
    gridStyle: { height: '100%', width: '100%' },
  };

  constructor(props) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.onSelectionChanged = this.onSelectionChanged.bind(this);
  }

  async logout() {
    localStorage.setItem('userAccessToken', '');
    await nhost.auth.signOut();
    window.location.href = '/';
  }

  openModal() {
    this.setState(() => {
      return { modalShow: true };
    });
  }

  closeModal() {
    this.setState(() => {
      return { modalShow: false };
    });
  }

  async rotatePort() {
    const selectedRows = this.state.gridRef.current.api.getSelectedRows();
    selectedRows.forEach(async (row) => {
      await apiServiceCustomResolvers.rotatePort({
        user_id: localStorage.getItem('userId'),
        ip: row.vpsIp,
        port: row.proxies[0].port,
      });
    });
  }

  async enableProxy() {
    const selectedRows = this.state.gridRef.current.api.getSelectedRows();
    selectedRows.forEach(async (row) => {
      console.log(row.proxies[0]);
      await apiServiceCustomResolvers.enableProxy({
        user_id: localStorage.getItem('userId'),
        ip: row.vpsIp,
        port: row.proxies[0].port,
        user_name: row.proxies[0].username,
        user_pass: row.proxies[0].pass,
      });
    });
  }

  async enableAllProxies() {
    await apiServiceCustomResolvers.enableAllProxies({
      user_id: localStorage.getItem('userId'),
      ip: this.state.rowData[0].vpsIp,
      user_name: this.state.rowData[0].proxies[0].username,
      user_pass: this.state.rowData[0].proxies[0].pass,
    });
  }

  changeRowData(data) {
    this.setState(() => {
      return { rowData: data };
    });
  }

  onLoadGrid = (params) => {
    this.setState(() => {
      return { gridRef: React.createRef({ ...params }) };
    });
    this.setState(() => {
      return { gridApi: params.api };
    });
  };

  async onCellValueChanged(event) {
    console.log('Data after change is', event.data);
    let newAdvert = {
      adItemId: event.data.adItemId,
      link: event.data.link,
      title: event.data.title,
      price: event.data.price,
      location: event.data.location,
      status: event.data.status,
      statusDescription: event.data.statusDescription,
      consoleGeneration: event.data.consoleGeneration,
      controllersCount: event.data.controllersCount,
    };
    await new Promise((r) => setTimeout(r, Math.random() * 1000));
    await apiService.updateAdvertByPk(newAdvert);
  }

  async onSelectionChanged() {
    const selectedRows = this.state.gridRef.current.api.getSelectedRows();
    if (selectedRows.length > 0) {
      this.setState(() => {
        return { selectedRow: true };
      });
    }
  }

  operateModems(oldModems) {
    let modems = [];
    oldModems.forEach((modem) => {
      let newModem = { ...modem };
      let modemProxy = modem.proxies[0];
      newModem['vpsIp'] = modem.vps ? modem.vps.ip : '';
      newModem['proxy'] = modemProxy
        ? modemProxy.ip +
          ':' +
          modemProxy.port +
          '@' +
          modemProxy.username +
          ':' +
          modemProxy.pass
        : '';

      modems.push(newModem);
    });

    console.log(modems);
    return modems;
  }

  async isAuthenticated() {
    const isAuthenticated = await nhost.auth.isAuthenticatedAsync();

    if (isAuthenticated) {
      console.log('User is authenticated');
      const accessToken = nhost.auth.getAccessToken();
      localStorage.setItem('userAccessToken', accessToken);
      const user = nhost.auth.getUser();
      localStorage.setItem('userId', user.id);
    } else {
      console.log('User is not authenticated');
      window.location.href = '/';
    }
  }

  async componentDidMount() {
    await this.isAuthenticated();

    const changeRowData = async (data) => {
      const changedModems = this.operateModems(data);
      this.setState(() => {
        return { rowData: changedModems };
      });
    };

    const observer = client.subscribe({
      query: SUBSCRIBE_MODEMS,
    });
    observer.subscribe({
      next(data) {
        changeRowData(data.data.modems);
      },
      error(err) {
        console.log(err);
      },
    });

    let modems = await apiService.getModems();
    const changedModems = this.operateModems(modems);
    this.setState(() => {
      return { rowData: changedModems };
    });
  }

  getRowId(params) {
    return params.data.id;
  }

  render() {
    return (
      <div>
        <Button
          className="addButton"
          onClick={() => {
            this.openModal();
          }}
          variant="primary"
        >
          Create VPS
        </Button>
        <Button
          disabled={!this.state.selectedRow}
          className="addButton"
          onClick={() => {
            this.rotatePort();
          }}
          variant="warning"
        >
          Rotate port
        </Button>
        <Button
          disabled={!this.state.selectedRow}
          className="addButton"
          onClick={() => {
            this.enableProxy();
          }}
          variant="warning"
        >
          Restart proxy
        </Button>
        <Button
          className="addButton"
          onClick={() => {
            this.enableAllProxies();
          }}
          variant="warning"
        >
          Restart all proxies
        </Button>
        <Button onClick={() => this.logout()} variant="danger" type="submit">
          Log out
        </Button>
        <AddVpsModal show={this.state.modalShow} onHide={this.closeModal} />
        <div className="ag-theme-alpine" style={{ height: 800, width: '100%' }}>
          <AgGridReact
            rowData={this.state.rowData}
            getRowId={this.getRowId}
            ref={this.state.gridRef}
            columnDefs={this.state.columnDefs}
            defaultColDef={this.state.defaultColDef}
            pivotPanelShow={'always'}
            rowGroupPanelShow={'always'}
            suppressAggFuncInHeader={true}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            onGridReady={this.onLoadGrid}
            rowSelection={'multiple'}
            // onCellValueChanged={this.onCellValueChanged}
            onSelectionChanged={this.onSelectionChanged}
            animateRows={true}
            enableRangeSelection={true}
          ></AgGridReact>
        </div>
      </div>
    );
  }
}
